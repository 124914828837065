<script>
import Layout from "../../layouts/main";
import axios from 'axios';

import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain

export default {

  components: {
    Layout,
      // ReportStatus 
  },
  data() {
    return {
        showMessage: false,
        status_first:null,
        full_name:null,
        Bank_Name:null,
        person_no:null,
        Member_no:null,
        Date_now:null,        
        Phone_no:null,
        Email:null,
        Total_Approved_Applications:[],
        Total_Rejected_Applications:[],
        Total_Incomplete_Applications:[],
        Submitted_and_Under_Review:[],
        searchValueTable:'',
        selectFilterSort: null,
        show_incomplete:false,
        
        items: [],
        arrItem:[],
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('submit') && urlParams.get('submit') === 'yes') 
        {   this.showMessage = true;    setTimeout(() => {this.showMessage = false}, 5000);  }
        this.fetchData();
    },
    created()
        {
            this.arrItem = this.items
        },
    
    methods: {
    fetchData() {
        var t = localStorage.getItem("accessToken");
        axios.post(`/applications/applicant_dashboard/`, {
            token: t,
          })
            .then(response => {
                const data = response.data;
                console.log('cheack the data came or not- aaj hi------- ',data.user_data.status)
                this.full_name          = `${data.user_data.section_a1.first_name} ${data.user_data.section_a1.sur_name}`,
                this.Bank_Name          = data.user_data.section_a1.branch;
                // this.person_no          = data.user_data.section_a1.person_no;
                // this.Member_no          = data.user_data.section_a1.member_no;
                this.Date_now           = data.user_data.section_a1.dob;
                this.Phone_no           = data.user_data.section_a1.home_contact;
                this.Email              = data.user_email.email;
                      
                this.show_incomplete    = data.user_data.status=='incomplete' ? true : false;
                
                this.status_first       = data.user_data.status;
                console.log('this is for only check ',this.status_first)
                })
            .catch(error => {
            console.error(error);
            });
        },
    },
};
</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <h2 class="font-size-22 text-theme-dark">Dashboard</h2>
            </div>
        </div>
        <h5 v-if="showMessage && status_first === 'submitted'" style="color: green;">Thank you! Your application for membership has been submitted and is under review. Please log back into this portal for updates on the status of your application.</h5>
        <br>

        <div class="row">
            <div class="col-lg-6 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h2 class="prsnNameStyle">{{ full_name }}</h2>
                        <ul class="listUlStyle mb-0">
                            <li>
                                <span class="font-weight-500">
                                    Branch :
                                </span>
                                <span>{{ Bank_Name }}</span>
                            </li>
                         
                            <li>
                                <span class="font-weight-500">
                                    Date :
                                </span>
                                <span>
                                    {{ Date_now }}
                                </span>
                            </li>
                            <li>
                                <span class="font-weight-500">
                                    Phone Number :
                                </span>
                                <span>
                                    {{ Phone_no }}
                                </span>
                            </li>
                            <li>
                                <span class="font-weight-500">
                                    Email :
                                </span>
                                <span>
                                    {{ Email }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row gx-2">
                            <div class="col">
                                <h3 class="prsnNameStyle text-start">Application Log</h3>
                            </div>
                            <div class="col-auto">
                                <router-link to="/view_log" class="btn btn-theme-dark btn-sm">View More</router-link>
                            </div>
                        </div>
                        <ul class="listTimeLineCstm list-unstyled mt-3 mb-0">
                            <li class="activeStatus">
                                <div class="arrowTypeStatus">
                                    <!-- <i class="bx bx-right-arrow-circle font-size-18 "></i> -->
                                    <i class="mdi mdi-check-circle text-theme font-size-18 "></i>
                                </div>
                                <div class="contentTypeStatus">
                                    <!--<h5 class="text-dark-custom font-size-15">{{ status_first }}</h5>-->
                                    <h5 class="text-dark-custom font-size-15">In-Progress</h5>
                                    <span class="dateStylePro text-muted"><i class="mdi mdi-clock me-2"></i> 10 June, 2023 - 10.00AM</span>
                                </div>
                            </li>
                            <li  :class="{ 'progressStatus': !show_incomplete, 'activeStatus': show_incomplete }">
                                <div class="arrowTypeStatus" v-if="show_incomplete">
                                    <i class="mdi mdi-check-circle text-theme font-size-18 "></i>
                                </div>
                                <div class="arrowTypeStatus" v-else>
                                    <i class="bx bx-right-arrow-circle font-size-18 text-theme bx-fade-right"></i>
                                </div>
                                <div class="contentTypeStatus">
                                    <h5 class="text-dark-custom font-size-15">Submitted and Under Review</h5>
                                    <span class="dateStylePro text-muted"><i class="mdi mdi-clock me-2"></i>20 June, 2023 - 09.40AM</span>
                                </div>
                            </li>
                            <li class="progressStatus" v-if="show_incomplete">
                                <div class="arrowTypeStatus">
                                    <i class="bx bx-right-arrow-circle font-size-18 text-theme bx-fade-right"></i>
                                </div>
                                <div class="contentTypeStatus">
                                    <h5 class="text-dark-custom font-size-15">Marked as incomplete</h5>
                                    <router-link to="/membership_application_edit" class="btn btn-theme-dark btn-sm">Edit</router-link>
                                </div>
                            </li>
                            <li>
                                <div class="arrowTypeStatus">
                                    <i class="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <div class="contentTypeStatus">
                                    <h5 class="text-dark-custom font-size-15">Inprogess</h5>
                                    <span class="dateStylePro text-muted"><i class="mdi mdi-clock me-2"></i> 30 June, 2023 - 09.40AM</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4">

            </div>
        </div>
    </main>
  </Layout>
</template>